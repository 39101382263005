import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../services/authService";
import lottie from 'lottie-web';
import ReactGA from 'react-ga4';
import applicationTrackerAnimation from '../assets/lottieanimations/application_tracker_demo';
import collegeListAnimation from '../assets/lottieanimations/college_list_demo';
import collegeDiscoveryAnimation from '../assets/lottieanimations/college_discovery_demo';

export default function Signup2() {

  useEffect(() => {
    document.title = "Platform | YouElite | Landing CoBo AP";
  }, []);


  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const applicationAnimationContainer = useRef(null);
  const collegeListContainer = useRef(null);
  const collegeDiscoveryContainer = useRef(null);
  const firstNameInputRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    let anim;
    if (applicationAnimationContainer.current) {
      anim = lottie.loadAnimation({
        container: applicationAnimationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: applicationTrackerAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }
  
    return () => anim && anim.destroy();
  }, []);
  
  useEffect(() => {
    let anim;
    if (collegeListContainer.current) {
      anim = lottie.loadAnimation({
        container: collegeListContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: collegeListAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }
  
    return () => anim && anim.destroy();
  }, []);
  
  useEffect(() => {
    let anim;
    if (collegeDiscoveryContainer.current) {
      anim = lottie.loadAnimation({
        container: collegeDiscoveryContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: collegeDiscoveryAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }
  
    return () => anim && anim.destroy();
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    try {
      // Call the register function and get the full response
      const response = await fetch('https://api.student.youelite.net/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name,
          surname,
          email,
          password,
          password_confirmation: password,
        }),
      });
  
      // If the response status is 403, handle the redirection
      if (response.status === 403) {
        const data = await response.json();
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
          return; // Stop further execution after redirection
        }
      }
  
      // If registration is successful, process the response normally
      if (response.ok) {
        const data = await response.json();
        setSuccess('Registration successful! Redirecting to email verification...');
        localStorage.setItem('user', JSON.stringify(data));
  
        ReactGA.event('sign_up', {
          method: 'Email', // Specify the sign-up method
          user_id: data.id, // Optional: use the user's ID from the response if available
        });
  
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16674606128/1eBsCMGB19IZELCYiY8-',  // Your conversion ID
          'value': 0.0,
          'currency': 'USD'
        });
  
        // Redirect to the onboarding page after 1 second
        setTimeout(() => {
          navigate('/onboarding-sat');
        }, 1); 
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed. Please check your details and try again.');
      }
    } catch (error) {
      setError(error.message);
      console.error('Registration error:', error.message);
    }
  };
  


  const focusOnForm = () => {
    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus(); // Focus on the first input field
      formRef.current.classList.add('highlight-form'); // Add CSS animation to highlight the form
      setTimeout(() => {
        formRef.current.classList.remove('highlight-form'); // Remove the animation after a short delay
      }, 2000); // Duration of the animation
    }
  };


  return (
    <div className="page-sign py-0">
      <Row className="g-0">
        <Col
          md="7"
          lg="5"
          xl="4"
          className="col-wrapper"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <Card
            className="card-sign"
            style={{
              width: '100%',
              maxWidth: '400px',
              padding: '20px',
            }}
          >
            <Card.Header>
              <Card.Title>Sign Up</Card.Title>
              <Card.Text>It's free to signup and only takes a minute.</Card.Text>
            </Card.Header>
            <Card.Body>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Surname</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <small>
                    By clicking <strong>Create Account</strong> below, you agree to our{' '}
                    <a href="https://youelite.net/terms-conditions/" target="_blank" rel="noopener noreferrer">terms of service</a> and privacy statement.
                  </small>
                </div>
                <Button type="submit" variant="primary" className="btn-sign">Create Account</Button>
              </Form>

              <div className="divider" style={{display:"none"}}><span>or sign up using</span></div>

              <Row className="gx-2" style={{display:"none"}}>
                <Col>
                  <Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button>
                </Col>
                <Col>
                  <Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              Already have an account? <Link to="/signin">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col
          className="d-none d-lg-block p-5"
          style={{
            backgroundColor: "#fcfbfb",
            borderLeft: "1px solid #e0e0e0",
            height: "100vh",
            overflowY: "auto",
          }}
        >
          {/* Main Header Section */}
          <div className="header-section mt-5" style={{ textAlign: "center", marginBottom: "40px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "34px", marginBottom: "10px" }}>
              Find Your Best Path to Top Universities
            </h1>
            <p style={{ color: "#6c757d", fontSize: "16px" }}>
              YouElite’s smart tools help you find the best universities and match with majors, all for free! No hidden fees, no stress. Just focus on crushing the SAT and your AP classes, and we’ll handle the rest. Everything you need is just a click away—easy peasy!
            </p>
          </div>

          {/* Button Section */}
          <div className="button-section" style={{ textAlign: "center", marginBottom: "40px" }}>
            <Button variant="primary" style={{ marginRight: "10px", padding: "10px 20px" }} onClick={focusOnForm}>
              Start, it is completely free!
            </Button>
            <Button
              variant="outline-dark"
              style={{ padding: "10px 20px" }}
              onClick={() => window.open("https://www.youelite.net", "_blank")}
            >
              Our Website
            </Button>
          </div>


          {/* Lower Part: College list / discovery */}
          <div className="ui-example-section" style={{ marginTop: "40px" }}>
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>You Focus on Your Grades, We Make Your Application Shine</h4>
                <p style={{ color: "#6c757d", fontSize: "14px" }}>
                  Skip the guesswork. With YouElite, you’ll get a personalized list of the best universities that match your academic profile and goals, making the process smooth and stress-free.
                </p>
              </Col>
              <Col style={{ padding: "20px" }}>
                <div style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "1 4px 15px rgba(0, 0, 0, 0.5)"
                }}>
                  <div 
                    ref={applicationAnimationContainer} 
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          </div>

          {/* Additional Components Section */}
          <div className="additional-components-section" style={{ marginTop: "40px" }}>
            <Row>
              {/* Left Component */}
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Find Your Ideal College</h4>
                <div style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "1 4px 15px rgba(0, 0, 0, 0.5)"
                }}>
                  <div 
                    ref={collegeDiscoveryContainer} 
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  ></div>
                </div>
                <p className="mt-1">Confused about which college to choose? No worries. Our AI-powered College Matchmaker helps you explore options from over 6,000 colleges based on your preferences and strengths, so you can make an informed decision for your future.</p>
              </Col>

              {/* Right Component */}
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Discover the Best Universities for You</h4>
                <div style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "1 4px 15px rgba(0, 0, 0, 0.5)"
                }}>
                  <div 
                    ref={collegeListContainer} 
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  ></div>
                </div>
                <p className="mt-1">Your AP courses and hard work are already setting you up for success. We’ll show you how to turn those credits into a winning college application that stands out.</p>
              </Col>
            </Row>
          </div>

          {/* New Section: Not yet convinced? We have many more features */}
          <div className="additional-features-section" style={{ marginTop: "40px" }}>
            <h2 style={{ textAlign: "center", fontWeight: "bold", marginBottom: "30px" }}>
              Still not sure? There's way more great features coming your way!
            </h2>
            <p style={{ color: "#6c757d", fontSize: "16px", textAlign: "center" }}>
              I mean, can I just say again that every feature is free to use? Like, are we in 2024?
            </p>
            {/* First Row of features */}
            {/* First Row of features */}
            <Row className="g-4 mt-1">
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Major Matchmaker</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Find the perfect major using federal data, matched to your profile. Get personalized suggestions and explore career paths that align with your true preferences!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>College List Builder</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Build a tailored college list that fits your profile. We take care of the details so you can focus on what matters most!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>College Comparison</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Compare colleges based on all your preferences using our smart algorithm, and find the perfect fit for your future.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Second Row of features */}
            <Row className="g-4" style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>College Discovery and Matching</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Filter from over 6,000 colleges and discover the one that truly matches your academic goals and personal preferences.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Scholarship Finder</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Find scholarships that align with your profile and fund your education without the stress. We’ll help you access the financial aid you need!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Application Tracker</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Track all your college applications in one place and stay organized throughout the process. Never miss a step!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Third Row of features */}
            <Row className="g-4" style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Deadline Management</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Stay on top of every college application deadline, ensuring you submit everything on time and never miss an opportunity.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Complete Guide on College Applications</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      It’s 100% free and covers everything—from preparing your application to moving into college. This is the only guide you'll ever need.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Application Analyzer</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Get a sneak peek into how admission officers think and evaluate your application, helping you boost your chances of getting accepted!
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Fourth Row of features */}
            <Row className="g-4" style={{ marginTop: "20px" }}>
              <Col md={6}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>Essay Review</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      AI-powered essay review with detailed sentence-by-sentence analytics, strengths, cons, and personalized comments to make your essay stand out.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                  <Card.Body>
                    <h5 style={{ fontWeight: "bold" }}>College Consulting</h5>
                    <p style={{ color: "#6c757d", fontSize: "14px" }}>
                      Chat with an AI chatbot trained with college admission consultants' expertise, designed to help you create the strongest application possible.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

          </div>

        </Col>
      </Row>
    </div>

    
  );
}
